@media (min-width: 1025px) {
  .main_header {
    display: none;
  }

  .is-sticky {
    z-index: 20;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #293e91;
    /* background: linear-gradient(90deg, #293e91 33%, rgb(0 212 255) 100%); */
    padding: 0 100px;
    transition: 0.6s;
  }

  header {
    z-index: 20;
    position: absolute;
    top: px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #293e91;
    /* background: linear-gradient(90deg, #293e91 33%, rgb(0 212 255) 100%); */
    padding: 0 100px;
    transition: 0.6s;
  }

  header .navigation {
    position: relative;
  }

  header .navigation .menu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    user-select: none;
  }

  .menu-item > a {
    color: #fff;
    font-size: 1em;
    margin: 0 15px;
    padding: 25px 0;
  }

  .menu-item > a:hover {
    color: #fff;
  }

  .menu-item .sub-menu {
    position: absolute;
    width: 25%;
    margin-left: -30px;
    background: #23252b;
    /* background: linear-gradient(90deg, #293e91 33%, rgb(0 212 255) 100%); */
    top: 74px;
    line-height: 46px;
    list-style: none;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
    pointer-events: none;
    /* transform: translateY(0); */
    transition: 0.3s;
    /* transition-property: transform, opacity; */
    opacity: 0;
  }

  .menu-item:hover .sub-menu {
    pointer-events: all;
    /* transform: translateY(0); */
    opacity: 1;
  }

  .menu-item .sub-menu .sub-item {
    position: relative;
    padding: 7px 0;
    cursor: pointer;
    box-shadow: inset 0 -30px 5px -30px rgba(255, 255, 255, 0.2);
  }

  .menu-item .sub-menu .sub-item a {
    color: #fff;
    font-size: 1em;
    text-decoration: none;
    padding: 15px 30px;
  }

  .menu-item .sub-menu .sub-item:hover {
    background: #293e91;
  }

  /* cng */
  .menu-item .sub-menu .cng {
    background: #4a4e58 !important;
  }

  .menu-item .sub-menu .cng:hover {
    background: linear-gradient(
      90deg,
      #28a745 33%,
      rgb(0 212 255 / 70%) 100%
    ) !important;
  }

  /* arena */
  .menu-item .sub-menu .arena:hover {
    background: linear-gradient(
      90deg,
      #121aad 33%,
      rgb(0 212 255 / 70%) 100%
    ) !important;
  }

  .menu-item .sub-menu .tour:hover {
    background: linear-gradient(
      90deg,
      #121aad 33%,
      rgb(0 212 255 / 70%) 100%
    ) !important;
  }

  .menu-item .sub-menu .sub-item:last-child:hover {
    border-radius: 0 0 8px 8px;
  }

  .more .more-menu {
    position: absolute;
    top: 0px;
    left: 101%;
    background: #23252b;
    list-style: none;
    white-space: nowrap;
    border-radius: 0 8px 8px 8px;
    overflow: hidden;
    pointer-events: none;
    transform: translateY(15px);
    opacity: 0;
    transition: 0.3s;
  }

  .more:hover .more-menu {
    pointer-events: all;
    transform: translateY(0);
    opacity: 1;
  }

  .more .more-menu .more-item {
    padding: 7px 0;
    box-shadow: inset 0 -30px 5px -30px rgba(255, 255, 255, 0.2);
    transition: 0.3s;
  }

  .more .more-menu .more-item:hover {
    background: #4080ef;
  }
}

/* min width closes */

@media (max-width: 1024px) {
  .main_header_01,
  .is-sticky {
    display: none;
  }
}
