.image-link {
  position: relative;
  display: inline-block;
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.image-link:hover .hover-text {
  opacity: 1;
  visibility: visible;
}
